import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { makeStyles  } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

class IFrameComponent extends React.Component {
    iframe() {
      return {
        __html: this.props.iframe
      }
    }
  
    render() {
      return (
        <div>
          <div dangerouslySetInnerHTML={ this.iframe() } />
        </div>
      );
    }
}

const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d981.1572007160279!2d76.30886036952329!3d10.003178778923298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080dd09eb3e655%3A0x1daf0bfdd58c323c!2sAPJMV%20%26%20Associates%20LLP!5e0!3m2!1sen!2sin!4v1652527162004!5m2!1sen!2sin" style="width:100%;height:190px;border:0;padding-top:10px;" frameborder="0" allowfullscreen></iframe>'; 

const useStyles = makeStyles ({
    root: {
        'padding-top': '10px'
    },
    root1 : {
        'padding-top': '5px'
    }
});

function Footer() {
    const classes = useStyles();
    return (
        <div style={{backgroundColor: '#EDEFF1', 'borderTop': '1px solid #ccc', 'borderBottom': '1px solid #ccc', marginTop: '15px'}}>
            <Container maxWidth="lg" >
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle2" gutterBottom classes={{root: classes.root}}>Our Services</Typography>
                        <Divider />
                        <Typography variant="body2" gutterBottom classes={{root: classes.root}}>Audit Services</Typography>
                        <Typography variant="body2" gutterBottom>Accounting and bookkeeping</Typography>
                        <Typography variant="body2" gutterBottom>Tax Planning</Typography>
                        <Typography variant="body2" gutterBottom>Tax Consultancy</Typography>
                        <Typography variant="body2" gutterBottom>Representation & Statutory Compliance</Typography>
                        <Typography variant="body2" gutterBottom>Feasibility Studies</Typography>
                        <Typography variant="body2" gutterBottom>Corporate Governance</Typography>
                        <Typography variant="body2" gutterBottom>Incorporation & Investment Advisory Services</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle2" gutterBottom classes={{root: classes.root}}>Contact Us</Typography>
                        <Divider />
                        <Typography variant="subtitle2" gutterBottom classes={{root: classes.root}}>{"A P J M V & ASSOCIATES LLP"}</Typography>
                        <Typography variant="body2" gutterBottom>1st Floor, 43/604 A1, Kavalath Road</Typography>
                        <Typography variant="body2" gutterBottom>Pipeline Crossing Junction, Palarivattom</Typography>
                        <Typography variant="body2" gutterBottom>Kochi, Kerala - 682025</Typography>
                        <Typography variant="body2" gutterBottom><Icon style={{'fontSize': '14px'}}>phone</Icon>{' '}<a href="tel:+914842347248">+91 484 2347248</a></Typography>
                        <Typography variant="body2" gutterBottom><Icon style={{'fontSize': '14px'}}>phone_android</Icon>{' '}<a href="tel:+918281949248">+91 8281949248</a></Typography>
                        <Typography variant="body2" gutterBottom><Icon style={{'fontSize': '14px'}}>email</Icon>{' '}<a href="mailto:contact@apjmv.com">contact@apjmv.com</a></Typography>
                        <Typography variant="body2" gutterBottom><Icon style={{'fontSize': '14px'}}>email</Icon>{' '}<a href="mailto:apjmvassociates@gmail.com">apjmvassociates@gmail.com</a></Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="subtitle2" gutterBottom classes={{root: classes.root}}>Reach Us</Typography>
                        <Divider />
                        <IFrameComponent iframe={iframe} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Footer;