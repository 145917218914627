import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Typography, Link } from '@material-ui/core';
import { makeStyles  } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import James from './ca_james.jpg'
import Venkatakrishnan from './ca_venkat.jpg'
import Murali from './ca_murali.jpg'
import Padmanabha from './ca_padmanabha_pillai.jpg'
import Alex from './ca_alex.jpg'
import Sreenath from './ca_sreenath.jpg'
import Anantharaman from './ca_anantharaman.jpg'
import Saleem from './ca_saleem.jpg'
import Krishna from './ca_krishna_raghupathy.jpg'
import Abraham from './ca_abraham_thomas.jpg'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ProfileModal from './ProfileModal.js';
import ProfileDialog from './ProfileDialog.js';

const useStyles = makeStyles ((theme) => ({
    root: {
        maxWidth: 220,
        
    },
    rootTyp: {
        paddingTop: '10px'
    }
}));

const theme = createMuiTheme();

const subtitle2 = {
    [theme.breakpoints.only('md')]: {
        fontSize: '0.530rem',
    },
    [theme.breakpoints.only('sm')]: {
        fontSize: '0.590rem',
    },
  ' @media (min-width:1140px)': {
        fontSize: '0.690rem',
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: '0.815rem',
    },
};
const body2 = {
    [theme.breakpoints.only('md')]: {
      fontSize: '0.590rem',
    },
    [theme.breakpoints.only('sm')]: {
        fontSize: '0.590rem',
    },
    '@media (min-width:1140px)': {
        fontSize: '0.690rem',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '0.815rem',
    },
};
Object.assign(theme.typography.subtitle2, subtitle2)
Object.assign(theme.typography.body2, body2)

console.log(theme);

const team1 = [
    {
        name: "James Menachery",
        id: "James",
        img: James,
        degree: "FCA",
        email: "jm@apjmv.com",
        designation: "Founder, Managing Partner",
        linkedin: "https://www.linkedin.com/in/james-menachery-12916241/",
        experience: [
            "James Menachery is a finance professional with extensive experience of over 40 years. He has rich experience in working for various industries like fertilizers, petro chemicals, consulting services and power and water utilities both in India, Indonesia and Saudi Arabia. His expertise spans across accounting, finance, financial control, financial modelling, budgetary control and taxation. He has significant experience in structuring SAP-FICO and PS modules. He was one of the initial members of the finance team at the Power And Water Utility Company (MARAFIQ), Jubail, Saudi Arabia and worked with them for over 10 years as Senior Financial Consultant.", 
            "His earlier assignments included Senior Financial Consultant at Multi-Sentra Konsultan, Jakarta, Indonesia for Costing and Budgetary Control implementation for leading Indonesian companies. He has also worked in the capacity of Senior Manager Finance for FACT, India."],
        qualification: [
            "He is a rank holder and a fellow member of the Institute of Chartered Accountants of India."
        ]
    },{
        name: "Venkatakrishnan S",
        id: "Venkatakrishnan",
        img: Venkatakrishnan,
        degree: "FCA",
        email: "sv@apjmv.com",
        designation: "Founder, Designated Partner",
        experience: [
            "Venkatakrishnan S, former Executive Director, Finance at The Fertilizers and Chemicals Travancore Limited (FACT) is a finance professional with over 44 years of rich experience out of which 33 years were with FACT. His vast experience includes financial restructuring, refinancing, arranging working capital loans, taxation work Fertilizer subsidy, accounting and audit. He has represented the Finance function in Board Meetings of the Company and has been involved in strategic decision making. His primary area of expertise is accounting, finance, financial control, financial modelling, budgetary control and approval of CAPEX procurement in the Company. He has managed Statutory and Government and other audits.", 
            "His earlier experience includes working as a partner for R. Dharmasankara Iyer and Co. He had handled audits of public sector banks, commercial establishments, GST & Income tax assessments and Company Law matters, Import Export practice."
        ],
        qualification: [
            "He is a fellow member of the Institute of Chartered Accountants of India."
        ]
    },{
        name: "Alex Jose",
        id: "Alex",
        img: Alex,
        degree: "FCA",
        email: "aj@apjmv.com",
        designation: "Founder, Partner",
        experience: [
            "Alex Jose is a finance professional with extensive experience in the finance field for over 45 years. He has served in various senior management positions at The Fertilizers and Chemicals Travancore Limited (FACT).",
            "His vast experience includes accounting, audit, financial management, insurance, taxation, forex management, project accounting and contract accounting. He is presently rendering CFO services to a leading engineering group in Kerala and handles financial planning and investment planning for them including compliance and taxation.",
            ""
        ],
        qualification: [
            "He is a fellow member of the Institute of Chartered Accountants of India."
        ]
    },{
        name: "Padmanabha Pillai S",
        id: "Padmanabha",
        img: Padmanabha,
        degree: "ACA",
        email: "sp@apjmv.com",
        designation: "Founder, Partner",
        linkedin: "https://www.linkedin.com/in/padmanabha-pillai-s-5405b4b3/",
        experience: [
            "Padmanabha Pillai has rich experience of over 40 years in the field of Finance. He has worked at The Fertilizers and Chemicals Travancore Limited (FACT) in various capacities including marketing. His last assignment was as General Manager Internal Audit. His vast experience includes accounting, audit, financial management, insurance, forex management, taxation and information technology. He has also conducted special audits and has facilitated statutory audit and Government audit. He also worked as finance head in a leading public sector company in Kerala.",
            "He has handled advisory services for capital markets, financial advisory services to large steel, aluminum and textile conglomerates. He is also rendering consultancy services to Non Banking Financial Companies (NBFC) and other commercial establishments."
        ],
        qualification: [
            "He is an associate member of the Institute of Chartered Accountants of India."
        ]
    },{
        name: "Murali Nair V",
        id: "Murali",
        img: Murali,
        degree: "FCA",
        email: "vm@apjmv.com",
        designation: "Founder, Partner",
        linkedin: "https://www.linkedin.com/in/murali-v-26678151/",
        experience: [
            "Murali Nair is a finance professional with extensive experience of over 40 years. He has worked at The Fertilizers and Chemicals Travancore Limited (FACT) in various capacities including marketing manager of FACT and his last assignment as General Manager Corporate Finance. His vast experience includes corporate finance, strategic planning, accounting, audit, financial management, insurance, forex management, taxation and information technology. He has also conducted special audits and has facilitated statutory audit and Government audit. One of the key areas of his focus was the financial restructuring of the company.",
            "He had practiced as CA under the name and style of V. Murali Nair and has handled statutory audit of public sector banks and audit of commercial establishments. He is also rendering consultancy to various start up projects."
        ],
        qualification: [
            "He is a fellow member of the Institute of Chartered Accountants of India."
        ]
    }
];

const team2 = [
    {
        name: "Sreenath V Kamath",
        id: "Sreenath",
        img: Sreenath,
        degree: "FCA, DISA",
        email: "sk@apjmv.com",
        designation: "Partner",
        experience: [
            "Srinath Kamath is a finance professional with rich experience of over 40 years. He has worked at The Fertilizers and Chemicals Travancore Limited (FACT) in various capacities including his last assignment as Chief General Manager Internal Audit. He has also served as Chief General Manager Corporate Finance. His vast experience includes corporate finance, strategic planning, accounting, audit, financial management, insurance, forex management, taxation and information technology. He has also conducted special audits and has facilitated statutory audit and Government audit. One of the key areas of his focus was the financial restructuring of the company.",
            "His earlier assignments included Audit Officer at New India Assurance Co. Ltd., Kerala region. He has also worked with Messrs. Varma & Varma, chartered accountants at their Kochi office."
        ],
        qualification: [
            "He is a fellow member of the Institute of Chartered Accountants of India (ICAI) and holds a Diploma in System Audit awarded by ICAI. He is also an Associate member of the Insurance Institute of India."
        ]
    },{
        name: "Krishna Kumar Raghupathy",
        id: "Krishna",
        img: Krishna,
        degree: "FCA",
        email: "rk@apjmv.com",
        designation: "Partner",
        linkedin: "https://www.linkedin.com/in/krishna-kumar-raghupathy-60884339/",
        experience: [
            "Raghupathy Krishna Kumar is a finance professional with over 38 years of experience working in various industries like automobile manufacturing, tractors, electronics & IT industry and power and water utilities both in India and in Saudi Arabia. He was one of the initial members of the finance team at the Power And Water Utility Company (MARAFIQ), Jubail, Saudi Arabia and has worked in the capacity of Accounting Manager and Financial Planning & Control Manager from 2003-2018.",
            "His earlier assignments included Vice President Finance Mangalore Power Company (a joint venture of Cogentrix USA and CLP Power of Hong Kong). He has also served as Business Development Manager for CLP Power International Hong Kong for the India region. He has excellent relationships with leading financial and legal consultants in the GCC and leading banks and financial institutions, developed during the Murabaha transactions, Sukuk issue and SIDF financing for MARAFIQ. His expertise covers, accounting, IFRS implementation, financial control, financing and VAT implementation. He has good exposure to SAP FICO, PS and SAP-BI."
        ],
        qualification: [
            "He is a fellow member of the Institute of Chartered Accountants of India."
        ]
    },{
        name: "Abraham Thomas",
        id: "Abraham",
        img: Abraham,
        degree: "FCA",
        email: "at@apjmv.com",
        designation: "Partner",
        experience: [
            "Abraham Thomas is a finance professional with rich experience of over 35 years. He has worked at The Fertilizers and Chemicals Travancore Limited (FACT) in various capacities in the area of finance. including his last assignment as Chief General Manager Internal Audit. His vast experience includes corporate finance, strategic planning, accounting, audit, financial management, insurance, forex management, and information technology.",
            "He has extensive international experience having worked in Saudi Arabia for over 20 years. His main areas of expertise include IFRS implementation, ERP implementation, designing and implementing Management Information Systems, Contract Management and Internal Audit. His latest assignment is that of Chief Financial officer of a mega manpower supply company in Saudi Arabia. He has excellent relationships with leading financial and legal consultants in the GCC and leading banks and financial institutions."
        ],
        qualification: [
            "He is a fellow member of the Institute of Chartered Accountants of India."
        ]
    },{
        name: "Anantharaman",
        id: "Anantharaman",
        img: Anantharaman,
        degree: "FCA",
        email: "contact@apjmv.com",
        designation: "Partner",
        experience: [
            "Anantharaman is a finance professional with over 35 years of industrial experience in the manufacturing and services sector. He was in a leadership role for over 14 years as  CFO in a leading food processing export company. Expert in setting up Systems, ERP Implementation and enabling business with IT enabled solutions. Key strength is in using No Code and Low Code platforms and developing workflow based low cost business solutions for the MSME sector. "
        ],
        qualification: [
            "He is a fellow member of the Institute of Chartered Accountants of India."
        ]
    }
];

function AboutUs() {
    const classes = useStyles();

    const [open, setOpen] = React.useState({});

    const handleOpen = (memberId) => {
        setOpen({'id': memberId});
    };

    const handleClose = () => {
        setOpen({});
    };

    return (
        <Container maxWidth="lg" style={{'paddingBottom': '10px'}}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <Typography variant="h5" classes={{root: classes.rootTyp}}>
                        {"About Us"}
                    </Typography>
                    <Typography variant="body1" classes={{root: classes.rootTyp}}>
                        {"A P J M V & ASSOCIATES LLP is a Limited Liability Partnership (LLP) firm. It is registered with Registrar of Companies, Ernakulam on Sep 21, 2017 by 5 chartered accountants with rich experience in accounting, management and consulting fields. Main business activities of A P J M V & Associates LLP are Audit, Consultancy, Management Services and associated activities. The LLP has 7 working partners and 2 designated partners. The designated partners are James Menachery and Venkatakrishnan Sivasailam."}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{paddingTop: "10px"}}>
                    <Link href="https://www.linkedin.com/company/a-p-j-m-v-associates-llp" target="_blank">
                        <LinkedInIcon fontSize="large" style={{fill: '#0e76a8'}}/>
                    </Link>
                    &nbsp;&nbsp;&nbsp;
                    <Link href="#" target="_blank">
                        <TwitterIcon fontSize="large" style={{fill: '#1976d2'}}/>
                    </Link>
                    &nbsp;&nbsp;&nbsp;
                    <Link href="#" target="_blank">
                        <FacebookIcon fontSize="large" style={{fill: 'rgb(17, 82, 147)'}}/>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" classes={{root: classes.rootTyp}}>
                        {"Our Team"}
                    </Typography>
                </Grid>
            </Grid>
            <ThemeProvider theme={theme}>
                <Grid container direction="row" spacing={4} style={{'paddingTop': '10px'}}>
                {team1.map((member) => (
                    <Grid item xs={12} sm={4} md>
                        <Card className={classes.root} onClick={() => handleOpen(member.id)}>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                alt={member.id}
                                image={member.img}
                                title={member.id}
                                />
                                <CardContent>
                                <Typography gutterBottom variant="subtitle2" component="p">
                                    CA. {member.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {member.designation}
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <ProfileDialog 
                            open={open.id === member.id ? true : false} 
                            handleClose={handleClose} 
                            name={member.name} 
                            degree={member.degree}
                            email={member.email}
                            experience={member.experience}
                            qualification={member.qualification}
                            linkedin={member.linkedin}
                        />
                    </Grid>
                ))}
                </Grid>
                <Grid container direction="row" spacing={4} justifyContent="space-around">
                {team2.map((member) => (
                    <Grid item xs={12} sm={4} md>
                        <Card className={classes.root} onClick={() => handleOpen(member.id)}>
                            <CardActionArea>
                                <CardMedia
                                component="img"
                                alt={member.id}
                                image={member.img}
                                title={member.id}
                                />
                                <CardContent>
                                <Typography gutterBottom variant="subtitle2" component="p">
                                    CA. {member.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {member.designation}
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                        <ProfileDialog 
                            open={open.id === member.id ? true : false} 
                            handleClose={handleClose} 
                            name={member.name} 
                            degree={member.degree}
                            email={member.email}
                            experience={member.experience}
                            qualification={member.qualification}
                            linkedin={member.linkedin}
                        />
                    </Grid>
                ))}
                </Grid>
            </ThemeProvider>
        </Container>
    );
}

export default AboutUs;