import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class Copyright extends React.Component {
    render() {
        return (
        <div style={{backgroundColor: '#e3e3e3', 'borderBottom': '1px solid #ccc', height: '35px', 'paddingTop': '15px'}}>
            <Container maxWidth="lg">
                <Grid container direction="row" alignItems="center" justify="center">
                    <Grid item xs={12} >
                        <Typography align="center" variant="body2">
                            {" Copyright © "} {(new Date().getFullYear())} {"APJMV & Associates LLP"}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
        );
    }
}

export default Copyright;