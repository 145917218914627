import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import { makeStyles  } from '@material-ui/core/styles';

class IFrameComponent extends React.Component {
    iframe() {
      return {
        __html: this.props.iframe
      }
    }
  
    render() {
      return (
        <div>
          <div dangerouslySetInnerHTML={ this.iframe() } />
        </div>
      );
    }
}

const iframeKochi = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d981.1572007160279!2d76.30886036952329!3d10.003178778923298!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080dd09eb3e655%3A0x1daf0bfdd58c323c!2sAPJMV%20%26%20Associates%20LLP!5e0!3m2!1sen!2sin!4v1652527162004!5m2!1sen!2sin" style="width:100%;height:270px;border:0;padding-top:10px;" frameborder="0" allowfullscreen></iframe>'; 
const iframeTvm = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.6203083058278!2d76.94307111916503!3d8.506669834549001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b05bbc1b38bd825%3A0x8db0636acacca7f0!2sVarambassery%20Junction%2C%20Barton%20Hill%20Colony%2C%20Kunnukuzhy%2C%20Thiruvananthapuram%2C%20Kerala%20695037!5e0!3m2!1sen!2sin!4v1590475757280!5m2!1sen!2sin" style="width:100%;height:200px;border:0;padding-top:10px;" frameborder="0" allowfullscreen></iframe>'; 
const iframeBlr = '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15548.63821209551!2d77.5772061!3d13.0255096!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf78cd3954c75c7e8!2sPurva%20Atria%20Platina!5e0!3m2!1sen!2sin!4v1590475601884!5m2!1sen!2sin" style="width:100%;height:270px;border:0;padding-top:10px;" frameborder="0" allowfullscreen></iframe>'; 

const useStyles = makeStyles ({
    root: {
        'padding-top': '10px'
    },
    root1 : {
        'padding-top': '5px'
    }
});

function ContactUs() {
    const classes = useStyles();
    return (
        <Container maxWidth="lg" style={{'paddingBottom': '10px'}}>
            <Typography variant="h5" classes={{root: classes.root}}>
                {"Contact Us"}
            </Typography>
            <Grid container direction="row" spacing={4}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" classes={{root: classes.root}}>Kochi Head Office</Typography>
                    <Divider />
                    <Typography variant="subtitle2" gutterBottom classes={{root: classes.root}}>{"A P J M V & ASSOCIATES LLP"}</Typography>
                    <Typography variant="body2" gutterBottom>1st Floor, 43/604 A1, Kavalath Road</Typography>
                    <Typography variant="body2" gutterBottom>Pipeline Crossing Junction, Palarivattom</Typography>
                    <Typography variant="body2" gutterBottom>Kochi, Kerala - 682025</Typography>
                    <Typography variant="body2" gutterBottom><Icon style={{'fontSize': '14px'}}>phone</Icon>{' '}<a href="tel:+914842347248">+91 484 2347248</a></Typography>
                    <Typography variant="body2" gutterBottom><Icon style={{'fontSize': '14px'}}>phone_android</Icon>{' '}<a href="tel:+918281949248">+91 8281949248</a></Typography>
                    <Typography variant="body2" gutterBottom><Icon style={{'fontSize': '14px'}}>email</Icon>{' '}<a href="mailto:contact@apjmv.com">contact@apjmv.com</a></Typography>
                    <Typography variant="body2" gutterBottom><Icon style={{'fontSize': '14px'}}>email</Icon>{' '}<a href="mailto:apjmvassociates@gmail.com">apjmvassociates@gmail.com</a></Typography>
                    <IFrameComponent iframe={iframeKochi} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" classes={{root: classes.root}}>Bangalore Branch</Typography>
                    <Divider />
                    <Typography variant="subtitle2" gutterBottom classes={{root: classes.root}}>{"A P J M V & ASSOCIATES LLP"}</Typography>
                    <Typography variant="body2" gutterBottom>No 501 A, Purva Atria Platina</Typography>
                    <Typography variant="body2" gutterBottom>R.M.V 2nd Stage, Aramane Nagar, Bengaluru - 560094</Typography>
                    <Typography variant="body2" gutterBottom>Karnataka, India</Typography>
                    <Typography variant="body2" gutterBottom><Icon style={{'fontSize': '14px'}}>phone_android</Icon>{' '}<a href="tel:+918296624111">+91 8296624111</a></Typography>
                    <Typography variant="body2" gutterBottom><Icon style={{'fontSize': '14px'}}>email</Icon>{' '}<a href="mailto:contact@apjmv.com">contact@apjmv.com</a></Typography>
                    <Typography variant="body2" gutterBottom><Icon style={{'fontSize': '14px'}}>email</Icon>{' '}<a href="mailto:apjmvassociates@gmail.com">apjmvassociates@gmail.com</a></Typography>
                    <Typography variant="body2" gutterBottom><br/></Typography>
                    <IFrameComponent iframe={iframeBlr} />
                </Grid>
            </Grid>
        </Container>
    );
}

export default ContactUs;