import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ImageGridList from './ImageGridList.js'
import LocationCityOutlinedIcon from '@material-ui/icons/LocationCityOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Typography } from '@material-ui/core';

class Home extends React.Component {
    render() {
        return (
          <Container maxWidth="md">
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={12}>
                        <ImageGridList />
                    </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center">
                    <Grid item xs={6} sm={6} style={{textAlign: "center", paddingTop: '10px'}}>
                        <VerifiedUserIcon style={{fill: '#0e76a8', fontSize: '3.5rem'}}/>
                        <Typography variant="h5">
                            9
                        </Typography>
                        <Typography variant="body2">
                            Qualified CAs with
                        </Typography>
                        <Typography variant="body2">
                            <b>40+</b> years experience
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} style={{textAlign: "center", paddingTop: '10px'}}>
                        <LocationCityOutlinedIcon style={{fill: '#0e76a8', fontSize: '3.5rem'}}/>
                        <Typography variant="h5">
                            2
                        </Typography>
                        <Typography variant="body2">
                            Offices
                            <br/><br/>
                        </Typography>
                    </Grid>
                </Grid>
          </Container>
        );
    }
}

export default Home;