import React from 'react';
import { Typography, Hidden, Link } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(3),
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(2),
        },
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    linkedin: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
});

const DialogTitle = withStyles(styles)((props) => {
    const { email, name, degree, linkedin, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h5">
            {`${name}, ${degree}`}
            {linkedin ? (
            <Link href={linkedin} target="_blank">
                <LinkedInIcon fontSize="small" style={{fill: '#0e76a8'}}/>
            </Link>
        ) : null}
        </Typography>
        <Typography>
            <Icon style={{'fontSize': '14px'}}>email</Icon>{' '}<a href={"mailto:" + email}>{email}</a>
        </Typography>
        {onClose ? (
            <Hidden xsDown="true">
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Hidden>
        ) : null}
      </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 3),
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(0, 2),
        },
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function ProfileDialog(props) {
    return (
        <Dialog 
            onClose={props.handleClose} 
            aria-labelledby="customized-dialog-title" 
            open={props.open}
            fullWidth="true"
            maxWidth="md"  
        >
            <DialogTitle 
                id="customized-dialog-title" 
                name={props.name} 
                degree={props.degree} 
                email={props.email} 
                onClose={props.handleClose} 
                linkedin={props.linkedin}/>
            <DialogContent>
                <Typography variant="h6" gutterBottom>
                    Professional Experience
                </Typography>
                {props.experience.map((exp) => (
                    <Typography gutterBottom variant="subtitle1">
                        {exp}
                    </Typography>
                ))}
                <Typography variant="h6" gutterBottom>
                    Professional qualification
                </Typography>
                {props.qualification.map((qual) => (
                    <Typography gutterBottom variant="subtitle1">
                        {qual}
                    </Typography>
                ))}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProfileDialog