import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles  } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles ((theme) => ({
    rootTyp: {
        paddingTop: '10px'
    },
    root: {
        width: '100%',
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '80%',
        flexShrink: 0,
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
      }
}));

function Services() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container maxWidth="lg" style={{'paddingBottom': '10px'}}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <Typography variant="h5" classes={{root: classes.rootTyp}}>
                        {"Services"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{'paddingTop': '10px'}}>
                <Grid item xs={12} sm={6}>
                    <div className={classes.root}>
                        <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            >
                                <Typography className={classes.heading}>Audit Services</Typography>
                                <Typography className={classes.secondaryHeading}>{' '}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <Typography>
                                    The following are the common audits conducted:
                                        <ul>
                                            <li>Tax Audit</li>
                                            <li>Company Audit</li>
                                            <li>Internal Audit</li>
                                            <li>Stock Audit</li>
                                        </ul>
                                </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                            >
                            <Typography className={classes.heading}>Accounting and Bookkeeping</Typography>
                            <Typography className={classes.secondaryHeading}>{' '}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                            {'Accounting and bookkeeping are among the core services we offer our clients as all businesses have to maintain accurate records, both for their own purposes and to comply with the law.'}
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header"
                            >
                            <Typography className={classes.heading}>Tax Planning</Typography>
                            <Typography className={classes.secondaryHeading}>{' '}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                            {"There are a number of legitimate ways to pay less tax while still complying with the law. Whether it's claiming your full entitlement on your self assessment return or fulfilling your dream and running your own business we can advise you of potential tax savings."}
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                            >
                            <Typography className={classes.heading}>Tax Consultancy</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                {'Tax consultants, also known as tax advisors, are experts in tax law, planning and compliance. They serve businesses and individuals alike by staying current on new tax law and positioning taxpayers for short and long term tax optimization. While tax consultants prepare tax returns, they also often work closely with clients throughout the year to ensure client financial errors are minimized. Tax consultants are strong written and verbal communicators able to explain tax law and strategies in a way that is accessible and easy for clients to understand.'}
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5bh-content"
                            id="panel5bh-header"
                            >
                            <Typography className={classes.heading}>{'Representation & Statutory Compliance'}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                <p>It is mandatory for all organizations or establishments are it is a proprietorship or a company to follow the statutory and legal compliances on time. In general scenario, compliance means binding to a law, following specific rules, policies and standards. Regulating authorities describe the laws, the corporations, advisors or agencies take care to comply with these laws and regulations.
                                </p>
                                <p>It is important for any establishment to maintain compliance requirements accurately as finance and accounts departments are the pillars in any organization. It is named to be first step to control your business.
                                </p>
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel6bh-content"
                            id="panel6bh-header"
                            >
                            <Typography className={classes.heading}>Feasibility Studies</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                {'A feasibility study tests the viability of an idea, a project or even a new business. The goal of a feasibility study is to place emphasis on potential problems that could occur if a project is pursued and determine if, after all significant factors are considered, the project should be pursued. Feasibility studies also allow a business to address where and how it will operate, potential obstacles, competition and the funding needed to get the business up and running.'}
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel7bh-content"
                            id="panel7bh-header"
                            >
                            <Typography className={classes.heading}>{'Incorporation & Investment Advisory Services'}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                <p>All services for incorporation of various forms of legal entities in India: Company (Private / Public), Partnership firms, Limited Liability partnerships, Trusts etc.</p>
                                <p>Assistance in inbound investment into India and formulation of entry strategy in the form of Liaison Office, Branch Office, Subsidiary Company etc. including assistance in making applications to the Reserve Bank of India (RBI) and Foreign Investment Promotion Board (FIPB).</p>
                                <p>Assistance in setting up subsidiaries outside India, including assistance in making applications to the Reserve Bank of India (RBI) for necessary approvals.</p>
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel8bh-content"
                            id="panel8bh-header"
                            >
                            <Typography className={classes.heading}>{'Management/Operational Audits'}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                <ul>
                                    <li>Appraisal of Controls</li>
                                    <li>Evaluation of Performance</li>
                                    <li>Internal Audit</li>
                                    <li>Appraisal of Organization structure</li>
                                </ul>
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel9bh-content"
                            id="panel9bh-header"
                            >
                            <Typography className={classes.heading}>{'Corporate Governance'}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                <p>Corporate governance is the system of rules, practices and processes by which a company is directed and controlled. Corporate governance essentially involves balancing the interests of a company's many stakeholders, such as shareholders, management, customers, suppliers, financiers, government and the community. Since corporate governance also provides the framework for attaining a company's objectives, it encompasses practically every sphere of management, from action plans and internal controls to performance measurement and corporate disclosure.</p>
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel10bh-content"
                            id="panel10bh-header"
                            >
                            <Typography className={classes.heading}>{'Mergers and Acquisitions'}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                <p>A merger is an agreement that unites two existing companies into one new company. There are several types of mergers and also several reasons why companies complete mergers. Mergers and acquisitions are commonly done to expand a company's reach, expand into new segments, or gain market share.</p>
						        <p>An acquisition is a corporate action in which a company buys most, if not all, of another firm's ownership stakes to assume control of it. An acquisition occurs when a buying company obtains more than 50% ownership in a target company. As part of the exchange, the acquiring company often purchases the target company's stock and other assets, which allows the acquiring company to make decisions regarding the newly acquired assets without the approval of the target company's shareholders. Acquisitions can be paid for in cash, in the acquiring company's stock or a combination of both.</p>
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel11bh-content"
                            id="panel11bh-header"
                            >
                            <Typography className={classes.heading}>{'Valuation Services'}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                Our services include:
                                <ul>
                                    <li>Business valuation</li>
                                    <li>Intangible asset valuation</li>
                                    <li>Tangible asset advisory services</li>
                                    <li>Dispute resolution support</li>
                                    <li>Fairness opinion</li>
                                    <li>Specialized services (including financial modeling)</li>
                                </ul>
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel12bh-content"
                            id="panel12bh-header"
                            >
                            <Typography className={classes.heading}>{'Due Diligence Reviews'}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                <p>Due diligence is an investigation of a business or person prior to signing a contract, or an act with a certain standard of care. It can be a legal obligation, but the term commonly applies to voluntary investigations.</p>
						        <p>Due Diligence Review (DDR) is a process, whereby an individual or an organization, seeks sufficient information about a business entity to reach an informed judgment as to its value for a specific purpose.</p>
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel13bh-content"
                            id="panel13bh-header"
                            >
                            <Typography className={classes.heading}>{'Fraud Investigation'}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                <p>A fraud investigation determines whether a scam has taken place and gathers evidence to protect the victims involved.</p>
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Services;