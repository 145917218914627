import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import logo from './logo.png'
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import ContentArea from './ContentArea.js'
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
}));

const options = [
    'Home',
    'About Us',
    'Services',
    'Contact Us'
];

const ITEM_HEIGHT = 48;

function NavBar() {
    const classes = useStyles();
    const handleClickSmUp = (event, page) => {
        event.preventDefault();
        //console.log(event, page);
        setPage(page);
    }
    const [page, setPage] = useState('home');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, page) => {
        setAnchorEl(null);
        if(page) {
            console.log(event.detail, page);
            setPage(page);
        }
    };

    return(
        <div>
            <AppBar position="fixed" style={{ backgroundColor: '#FFF', 'boxShadow': '0 0 6px rgba(0,0,0,0.2)' }}>
                <Container maxWidth="lg">
                    <div className={classes.root}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={6} sm={3} md={7}>
                                <Toolbar style={{ padding: '0px' }} >
                                    <Link href="#" onClick={e => handleClickSmUp(e, 'home')}>
                                        <img src={logo} alt="logo" style={{width: 'auto', height: '45px'}}/>
                                    </Link>
                                </Toolbar>
                            </Grid>
                            <Hidden xsDown="true">
                                <Grid item sm={9} md={5}>
                                    <Grid container direction="row" alignItems="center" alignContent="flex-end">
                                        <Grid item sm={3}>
                                            <Typography style={{'textAlign': 'right'}}>
                                                <Link href="#" onClick={e => handleClickSmUp(e, 'home')} underline="none" color="textSecondary" style={(page === 'home') ? {color: '#000'} : {}}>
                                                    Home
                                                </Link>
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={3}>
                                            <Typography style={{'textAlign': 'right'}}>
                                                <Link href="#" onClick={e => handleClickSmUp(e, 'aboutus')} underline="none" color="textSecondary" style={(page === 'aboutus') ? {color: '#000'} : {}}>
                                                    About Us
                                                </Link>
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={3}>
                                            <Typography style={{'textAlign': 'right'}}>
                                                <Link href="#" onClick={e => handleClickSmUp(e, 'services')} underline="none" color="textSecondary" style={(page === 'services') ? {color: '#000'} : {}}>
                                                    Services
                                                </Link>
                                            </Typography>
                                        </Grid>
                                        <Grid item sm={3}>
                                            <Typography style={{'textAlign': 'right'}}>
                                                <Link href="#" onClick={e => handleClickSmUp(e, 'contactus')} underline="none" color="textSecondary" style={(page === 'contactus') ? {color: '#000'} : {}}>
                                                    Contact Us
                                                </Link>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Hidden smUp="true">
                                <Grid item xs={6}>
                                    <Grid container direction="row" alignItems="center" alignContent="flex-end">
                                        <Grid item xs={12}>
                                            <div style={{'textAlign': 'right'}}>
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleClick}
                                                >
                                                    <MenuIcon />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={open}
                                                    onClose={e => handleClose(e, null)}
                                                    PaperProps={{
                                                    style: {
                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                        width: '20ch',
                                                    },
                                                    }}
                                                >
                                                    {options.map((option) => (
                                                    <MenuItem key={option} onClick={e => handleClose(e, option.toLowerCase().split(" ").join(""))}>
                                                        {option}
                                                    </MenuItem>
                                                    ))}
                                                </Menu>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </div>
                </Container>
            </AppBar>
            <Toolbar />
            <ContentArea page={page} />
        </div>
    )
}
export default NavBar;