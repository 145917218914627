import React from 'react';
import Home from './Home.js'
import ContactUs from './ContactUs.js'
import AboutUs from './AboutUs.js'
import Services from './Services.js'

class ContentArea extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.page === 'home') {
            return (<Home />);
        }

        if (this.props.page === 'aboutus') {
            return (<AboutUs />);
        }

        if (this.props.page === 'services') {
            return (<Services />);
        }

        if (this.props.page === 'contactus') {
            return (<ContactUs />);
        }
    }
}

export default ContentArea;