import React from 'react';
import NavBar from './NavBar.js';
import Copyright from './Copyright.js';
import Footer from './Footer.js';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const globalTheme = createMuiTheme();

function App() {
  return (
    <ThemeProvider theme={globalTheme}>
      <div className="App">
        <div>
          <NavBar />
          <Footer />
          <Copyright />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
