import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import banner from './banner.png'
import accBanner from './accounting_banner.jpg';
import accFinDept from './accounting-finance-department.jpg';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 750,
    height: 510,
  },
  gridListXs: {
    width: 650,
    height: 260,
  }
}));

const tileData = [{
    img: banner,
    title: 'Banner',
    author: 'author',
    cols: 6
}, {
    img: accBanner,
    title: 'Accounting Banner',
    author: 'author',
    cols: 3
}, {
    img: accFinDept,
    title: 'Accounting Finance Department',
    author: 'author',
    cols: 3
}];

export default function ImageGridList() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Hidden smUp="true">
            <GridList cellHeight={110} className={classes.gridListXs} cols={6}>
                {tileData.map((tile) => (
                <GridListTile key={tile.img} cols={tile.cols || 1}>
                    <img src={tile.img} alt={tile.title} />
                </GridListTile>
                ))}
            </GridList>
        </Hidden>
        <Hidden xsDown="true">
            <GridList cellHeight={250} className={classes.gridList} cols={6}>
                {tileData.map((tile) => (
                <GridListTile key={tile.img} cols={tile.cols || 1}>
                    <img src={tile.img} alt={tile.title} />
                </GridListTile>
                ))}
            </GridList>
        </Hidden>
    </div>
  );
}
