import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles  } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles ((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '3px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));

function ProfileModal(props) {
    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
            timeout: 500,
            }}
        >
            <Container maxWidth="md">
            <Fade in={props.open}>
                <div className={classes.paper}>
                    <h2>{props.name}, {props.degree}</h2>
                    <Typography variant="body1" gutterBottom>
                        <Icon style={{'fontSize': '14px'}}>email</Icon>{' '}<a href={"mailto:" + props.email}>{props.email}</a>
                    </Typography>
                    
                    <h3>Professional Experience</h3>
                    {props.experience.map((exp) => (
                        <p>{exp}</p>
                    ))}
                    <h3>Professional Qualification</h3>
                    {props.qualification.map((qual) => (
                        <p>{qual}</p>
                    ))}
                </div>
            </Fade>
            </Container>
        </Modal>
    );
}

export default ProfileModal